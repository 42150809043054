import * as React from "react";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo";
import {graphql} from "gatsby";
import Flats from "../../components/flats/flats";
import * as styles from "./house.module.scss";
import gp2_1 from "../../../static/images/2_1.png";
import gp2_2 from "../../../static/images/2_2.png";

const House2Page = ({data}) => {
  const flats = data.api.flats.filter((flat) => flat.id.startsWith("2"))
  const gpImages = [gp2_1, gp2_2];
  const maxFloor = 2;
  const gpWidth = 1460;
  const flatsOnGP = [
    {
      id: "2D",
      floor: 1,
      textCenter: "300,700",
      coords: "521,122,521,494,626,494,624,936,604,936,604,1105,3,1105,3,122"
    },
    {
      id: "2E",
      floor: 1,
      textCenter: "1000,650",
      coords: "606,1101,1455,1101,1455,476,1334,476,1336,156,849,156,849,324,716,324,715,494,628,494,628,939,606,939"
    },
    {
      id: "2F",
      floor: 1,
      textCenter: "600,300",
      coords: "709,161,524,492"
    },
    {
      id: "2F",
      floor: 2,
      textCenter: "700,500",
      coords: "3,117,538,117,538,156,1334,156,1334,474,1261,476,1264,989,604,988,602,949,113,952,115,748,1,752"
    }
  ];

  return (
    <Layout contactInfo={data.api.contactInfo}>
      <Seo title="Dům II" />

      <section className={styles.wrapper}>
        <h1 className={styles.title}>Dům II</h1>
      </section>
      {flats.length !== 0 ?
        <Flats flats={flats}
               flatsOnGP={flatsOnGP}
               gpImages={gpImages}
               gpWidth={gpWidth}
               maxFloor={maxFloor}
        />
        :
        <h4 className={styles.message}>Nabídku bytů pro Vás připravujeme</h4>
      }
    </Layout>
  )
}

export const query = graphql`
    query House2PageQuery {
        api {
            contactInfo:contactSingleton {
                email
                phone
                name
            }
            flats:flatsCollection(sort: {_o: 1}) {
                id
                floor
                dispositions
                area
                terrace
                garden
                garage
                price
                state
            }
        }
    }
`;

export default House2Page;

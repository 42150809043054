// extracted by mini-css-extract-plugin
export var container = "flats-module--container--2c9nE";
export var flatsCanvas = "flats-module--flats-canvas--opI2i";
export var floorTitle = "flats-module--floor-title--3G-8_";
export var groundplan = "flats-module--groundplan--1NNL7";
export var groundplanImage = "flats-module--groundplan-image--2BKhg";
export var groundplanWrapper = "flats-module--groundplan-wrapper--1pvmJ";
export var headline = "flats-module--headline--3FA7d";
export var line = "flats-module--line--11Duh";
export var next = "flats-module--next--1V415";
export var prev = "flats-module--prev--2TU9u";
export var switchButton = "flats-module--switchButton--29SFA";
export var tableWrapper = "flats-module--table-wrapper--1CHpe";
export var visible = "flats-module--visible--3C7SN";